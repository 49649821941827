.user-table-tablerow th {
  background-color: #1c53a4;
  color: white;
  border-right: 1px solid white;
}

.offline {
  color: orangered;
  font-size: 14px;
  font-weight: 700;
}
.online {
  color: green;
  font-size: 14px;
  font-weight: 700;
}
.basic_class {
  text-transform: capitalize !important;
  /* color: #20c1e9; */
  font-weight: 900;
}

.prouser {
  content: "";
  --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.prouser::before {
  content: "";
  background-color: #20c1e9;
  --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.prouser::after {
  content: "*";
  text-transform: capitalize !important;
  --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
  font-weight: 900;
}

.bbdhuuser {
  content: "";
   --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.bbdhuuser::before {
  content: "";
  background-color: #20c1e9;
   --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.bbdhuuser::after {
  content: "*";
  text-transform: capitalize !important;
   --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
  font-weight: 900;
}

.bpdhuuser {
  content: "";
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.bpdhuuser::before {
  content: "";
  background-color: #20c1e9;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.bpdhuuser::after {
  content: "*";
  text-transform: capitalize !important;
  --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
  font-weight: 900;
}

.tsuser {
  content: "";
  color: rgb(204, 177, 23) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.tsuser::before {
  content: "";
  background-color: #20c1e9;
  color: rgb(204, 177, 23) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.tsuser::after {
  content: "*";
  text-transform: capitalize !important;
  color: rgb(204, 177, 23) !important;
  font-weight: 900;
}

.stopuser {
  text-transform: capitalize !important;
  color: red;
  text-decoration: line-through;
  font-weight: 700;
}
